import React, { useState, useEffect } from "react";
import { Pane, Heading, Paragraph, Button, Table, TextInputField, Dialog, toaster } from "evergreen-ui";
import { BsPlusLg } from "react-icons/bs";
import { motion } from "framer-motion";
import axios from "axios";
import { Dialog as MaterialDialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button as MaterialButton } from "@mui/material";


const IMSSPage = ({ user }) => {
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [isDetailsDialogShown, setIsDetailsDialogShown] = useState(false); // Para el modal de detalles
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false); // Nuevo estado para el modal de confirmación
  const [selectedEmpleado, setSelectedEmpleado] = useState(null); // Empleado seleccionado
  const [searchQuery, setSearchQuery] = useState(""); // Nuevo estado para la búsqueda
  const [empleadoIdToCancel, setEmpleadoIdToCancel] = useState(null); // Empleado a cancelar la suscripción


  const [newUser, setNewUser] = useState({
    nombre: "",
    apellido: "",
    nss: "",
    curp: "",
    rfc: "",
    correo: "",
    telefono: "",
    seguro: "", // Necesitarás obtener el ID del seguro para asociarlo
    estadoSuscripcion: "Activo",
    autorizado: false,
  });
  const [empleados, setEmpleados] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCheckout = (empleadoId) => {
    const url = `https://buy.stripe.com/14k8yhboF4x87x6eUU?client_reference_id=${empleadoId}`;
    window.location.href = url;
  };

  const cancelarSuscripcion = async (empleadoId) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/cancelar-suscripcion/${empleadoId}`);
      console.log("Suscripción cancelada:", res.data);
      toaster.notify("Suscripción cancelada correctamente.");
      setEmpleados((prev) => prev.map((empleado) => 
        empleado._id === empleadoId ? { ...empleado, estadoSuscripcion: "Cancelado" } : empleado
      ));
    } catch (error) {
      console.error("Error al cancelar la suscripción:", error);
      toaster.danger("Error al cancelar la suscripción.");
    }
  };
  const handleOpenConfirmationDialog = (empleadoId) => {
    setEmpleadoIdToCancel(empleadoId);
    setIsConfirmationDialogOpen(true);
  };

  const handleCloseConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmCancelSubscription = () => {
    cancelarSuscripcion(empleadoIdToCancel);
    handleCloseConfirmationDialog();
  };

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };

  const filteredEmpleados = empleados.filter((empleado) =>
    `${empleado.nombre} ${empleado.apellido}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
    empleado.nss.toLowerCase().includes(searchQuery.toLowerCase()) ||
    empleado.rfc.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const handleSubmit = () => {
    setLoading(true);
    const empleadoData = {
      nss: newUser.nss,
      curp: newUser.curp,
      rfc: newUser.rfc,
      nombre: newUser.nombre,
      apellido: newUser.apellido,
      correo: newUser.correo,
      telefono: newUser.telefono,
      seguro: "", // Asegúrate de tener el ID del seguro
    };

    try {
      axios.post(`${process.env.REACT_APP_API_URL}/users/${user._id}/empleados`, empleadoData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { empleado } = res.data;
        setEmpleados((prev) => [...prev, empleado]); // Actualiza la lista de empleados
        toaster.notify("Empleado creado y asignado correctamente.");
        setIsDialogShown(false); // Cierra el modal
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al crear el empleado:", error);
        toaster.danger("Error al crear el empleado.");
        setLoading(false);
      });
    } catch (error) {
      console.error("Error en la solicitud:", error);
      toaster.danger("Error al crear el empleado.");
      setLoading(false);
    }
  };

  // Función para abrir el modal de detalles
  const handleVerDetalles = (empleado) => {
    setSelectedEmpleado(empleado); // Guardar el empleado seleccionado
    setIsDetailsDialogShown(true); // Mostrar el modal de detalles
  };

  // Cargar la lista de empleados al cargar el componente
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/${user._id}/empleados`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data.empleados)) {
          setEmpleados(res.data.empleados); // Verifica que 'empleados' es un array
        } else {
          console.error("Los empleados no se devolvieron como un array");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al cargar empleados:", error);
        setLoading(false);
      });
  }, [user._id]);

  return (
    <motion.div
      key={"seguros"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Pane
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={40}
        color="white"
        zIndex={1}
        className="imss-header"
      >
        <Heading size={800} color="white">
          Membresías
        </Heading>
      </Pane>
      
      <Pane
        padding={40}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        gap={20}
      >
        <Pane
          backgroundColor="#333342"
          padding={20}
          borderRadius={10}
          display="flex"
          flexDirection="column"
          gap={20}
          width="100%"
          maxWidth="1200px"
        >
          {/* Botón para abrir el modal */}
          <Button
            iconBefore={<BsPlusLg />}
            color="white"
            backgroundColor="#006b3e"
            className="btn-add"
            width="150px"
            marginBottom={20}
            onClick={() => setIsDialogShown(true)}
          >
            Agregar Miembro
          </Button>
          <TextInputField
            placeholder="Buscar miembro por nombre, NSS o RFC"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />


          {/* Modal para agregar nuevo empleado */}
          <Dialog
            isShown={isDialogShown}
            title="Registrar nuevo miembro en el plan de salud"
            onCloseComplete={() => setIsDialogShown(false)}
            confirmLabel="Enviar solicitud de inscripción"
            onConfirm={handleSubmit}
            isConfirmLoading={loading}
            width="600px"
          >
            <Pane marginBottom={24}>
              <Heading size={500} marginBottom={8}>Proceso de Inscripción</Heading>
              <Paragraph color="#474d66">
                Para inscribir a un miembro en el plan de salud, necesitamos verificar su información. El proceso es el siguiente:
              </Paragraph>
              <Pane marginY={16}>
                <Paragraph color="#474d66">1. Completa el formulario con los datos del empleado</Paragraph>
                <Paragraph color="#474d66">2. Nuestro equipo revisará la información en menos de 24 horas</Paragraph>
                <Paragraph color="#474d66">3. Una vez autorizado, recibirás una notificación para proceder con el pago</Paragraph>
                <Paragraph color="#474d66">4. Al confirmar el pago, tu nuevo miembro tendrá acceso inmediato a todos los servicios</Paragraph>
              </Pane>
            </Pane>            <TextInputField
              label="Nombre completo"
              name="nombre"
              value={newUser.nombre}
              onChange={handleInputChange}
              placeholder="Ingrese el nombre completo"
            />
            <TextInputField
              label="Apellido"
              name="apellido"
              value={newUser.apellido}
              onChange={handleInputChange}
              placeholder="Ingrese el apellido"
            />
            <TextInputField
              label="NSS"
              name="nss"
              value={newUser.nss}
              onChange={handleInputChange}
              placeholder="Ingrese el NSS"
            />
            <TextInputField
              label="CURP"
              name="curp"
              value={newUser.curp}
              onChange={handleInputChange}
              placeholder="Ingrese el CURP"
            />
            <TextInputField
              label="RFC"
              name="rfc"
              value={newUser.rfc}
              onChange={handleInputChange}
              placeholder="Ingrese el RFC"
            />
            <TextInputField
              label="Correo electrónico"
              name="correo"
              value={newUser.correo}
              onChange={handleInputChange}
              placeholder="Ingrese el correo electrónico"
            />
            <TextInputField
              label="Teléfono"
              name="telefono"
              value={newUser.telefono}
              onChange={handleInputChange}
              placeholder="Ingrese el número de teléfono"
            />
            {/* Aquí podrías añadir un campo para seleccionar el seguro */}
          </Dialog>

          {/* Modal para ver detalles del empleado */}
          {selectedEmpleado && (
            <Dialog
              isShown={isDetailsDialogShown}
              title="Detalles del Empleado"
              onCloseComplete={() => setIsDetailsDialogShown(false)}
              confirmLabel="Cerrar"
            >
              <Pane>
                <Paragraph><strong>Nombre:</strong> {selectedEmpleado.nombre} {selectedEmpleado.apellido}</Paragraph>
                <Paragraph><strong>NSS:</strong> {selectedEmpleado.nss}</Paragraph>
                <Paragraph><strong>CURP:</strong> {selectedEmpleado.curp}</Paragraph>
                <Paragraph><strong>RFC:</strong> {selectedEmpleado.rfc}</Paragraph>
                <Paragraph><strong>Correo:</strong> {selectedEmpleado.correo}</Paragraph>
                <Paragraph><strong>Teléfono:</strong> {selectedEmpleado.telefono}</Paragraph>
                <Paragraph><strong>Estado de suscripción:</strong> {selectedEmpleado.estadoSuscripcion}</Paragraph>
              </Pane>
            </Dialog>
          )}

          <Heading size={800} color="white">
            Lista de miembros
          </Heading>
          <Paragraph fontSize="1em" fontWeight="bold" color="#e1e4f4">
            Gestión de miembros y sus suscripciones la Membresía
          </Paragraph>
          <Table>
            <Table.Head>
              <Table.SearchHeaderCell flexBasis={150} flexShrink={0} />
              <Table.TextHeaderCell flexBasis={150} flexShrink={0}>
                NSS
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={150} flexShrink={0}>
                RFC
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexGrow={2}>
                Estado de la suscripción
              </Table.TextHeaderCell>
              <Table.TextHeaderCell flexBasis={250} flexShrink={0}>
                Acciones
              </Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {filteredEmpleados.map((empleado) => (
                <Table.Row key={empleado._id}>
                  <Table.TextCell flexBasis={150} flexShrink={0}>
                    {empleado.nombre}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={150} flexShrink={0}>
                    {empleado.nss}
                  </Table.TextCell>
                  <Table.TextCell flexBasis={150} flexShrink={0}>
                    {empleado.rfc}
                  </Table.TextCell>
                  <Table.Cell flexGrow={2}>
                    <Paragraph fontSize="1em" fontWeight="bold" color="black">
                      {empleado.estadoSuscripcion}
                    </Paragraph>
                  </Table.Cell>
                  <Table.TextCell flexBasis={250} flexShrink={0}>
                    <Pane display="flex" gap={8}>
                      <Button minWidth="120px" onClick={() => handleVerDetalles(empleado)}>
                        Ver detalles
                      </Button>
                      {empleado.estadoSuscripcion === "Activo" ? (
                        <Button
                        onClick={() => handleOpenConfirmationDialog(empleado._id)}
                        appearance="primary"
                        intent="danger"
                      >
                        Cancelar suscripción
                      </Button>
                    ) : (
                        <Button onClick={() => handleCheckout(empleado._id)} appearance="primary" minWidth="120px">
                          Pagar suscripción
                        </Button>
                      )}
                    </Pane>
                  </Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>

          </Table>
        </Pane>
      </Pane>
      <MaterialDialog
        open={isConfirmationDialogOpen}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle>{"Confirmar cancelación de suscripción"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas cancelar la suscripción de este empleado? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={handleCloseConfirmationDialog} color="primary">
            Cancelar
          </MaterialButton>
          <MaterialButton onClick={handleConfirmCancelSubscription} color="error">
            Confirmar
          </MaterialButton>
        </DialogActions>
      </MaterialDialog>

    </motion.div>
  );
};

export default IMSSPage;
