import React from "react";
import { useState, useEffect } from "react";
import {
  Combobox,
  Heading,
  Pane,
  TextInputField,
  Button,
  toaster,
  Tablist,
  Tab,
  Table,
} from "evergreen-ui";
import { useFormik } from "formik";

const FormularioAportaciones = (props) => {
  console.log("props", props);
  const mes = props.mes;
  const year = props.year; // Obtenemos el año de los props
  const aportaciones = props.aportaciones;
  
  const formik = useFormik({
    initialValues: {
      mes: "",
      monto: 0,
      year: year, // Inicializamos con el año recibido
    },
    onSubmit: props.onSubmit,
  });

  const [aportacion, setAportacion] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const mesId = getMesId(mes);
    // Filtramos por mes y año
    const aportacionesDelMes = aportaciones.filter((aportacion) => {
      return aportacion.mes === mesId && aportacion.year === year;
    });
    setAportacion(aportacionesDelMes);
    const suma = aportacionesDelMes.reduce((a, b) => a + b.monto, 0);
    formik.values.mes = mesId;
    formik.values.year = year; // Actualizamos el año en formik
    setTotal(suma);
  }, [mes, year, aportaciones]); // Agregamos year como dependencia

  const getMesId = (mes) => {
    switch (mes) {
      case "Enero":
        return "0";
      case "Febrero":
        return "1";
      case "Marzo":
        return "2";
      case "Abril":
        return "3";
      case "Mayo":
        return "4";
      case "Junio":
        return "5";
      case "Julio":
        return "6";
      case "Agosto":
        return "7";
      case "Septiembre":
        return "8";
      case "Octubre":
        return "9";
      case "Noviembre":
        return "10";
      case "Diciembre":
        return "11";
      default:
        return "0";
    }
  };

  return (
    <Pane>
      {aportacion.length > 0 ? (
        <Pane>
          <Heading size={600} marginTop={20}>
            Aportaciones del mes de {mes} {year} - Total: {"$"}{total}
          </Heading>
          <Pane display="flex" flexDirection="row" marginTop={20}>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell>Monto</Table.TextHeaderCell>
                <Table.TextHeaderCell>Fecha de registro</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {aportacion.map((aportacion) => (
                  <Table.Row key={aportacion.id}>
                    <Table.TextCell>{aportacion.monto}</Table.TextCell>
                    <Table.TextCell>{aportacion.fecha}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
        </Pane>
      ) : (
        <Pane>
          <Heading size={600} marginTop={20}>
            Aportaciones del mes de {mes} {year} - Total: {"$"}{total}
          </Heading>
          <Pane display="flex" flexDirection="row" marginTop={20}>
            <Table>
              <Table.Head>
                <Table.TextHeaderCell>Monto</Table.TextHeaderCell>
                <Table.TextHeaderCell>Fecha de registro</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.TextCell>No hay aportaciones</Table.TextCell>
                  <Table.TextCell>No hay aportaciones</Table.TextCell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Pane>
        </Pane>
      )}
      <Pane display="flex" flexDirection="row" marginTop={20}>
        <Pane width={300}>
          <TextInputField
            label="Monto"
            name="monto"
            type="number"
            value={formik.values.monto}
            onChange={formik.handleChange}
          />
          <Button
            appearance="primary"
            intent="success"
            onClick={() => {
              console.log("formik", formik.values);
              props.uploadAportacion(formik.values);
            }}
          >
            Registrar
          </Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default FormularioAportaciones;