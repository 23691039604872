import { Button, Heading } from "evergreen-ui";
import React from "react";
import { useState, useEffect, useLayoutEffect } from "react";
import {
  Overlay,
  Pane,
  Dialog,
  toaster,
  Paragraph,
  RadioGroup,
  TextInputField,
} from "evergreen-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import imgbtc from "../../../img/Manu071BTC.jpeg";
import imgBEP20 from "../../../img/Manu071BEP20.jpeg"
import imgUSDT from "../../../img/Manu071USDT.jpeg"

import { Tooltip } from "recharts";
import axios from "axios";
import { initAxiosInterceptors } from "../../../util/authHelper";

initAxiosInterceptors();
const Aportaciones = (props) => {
  const pasos = [
    {
      id: 1,
      title: "Tipo de aportacion",
      description: "Ingresa los datos de la aportación",
      active: true,
      completed: false,
    },
    {
      id: 2,
      title: "Datos de la aportación",
      description: "Ingresa los datos de la aportación",
      active: false,
      completed: false,
    },
    {
      id: 3,
      title: "Confirmación",
      description: "Confirma los datos de la aportación",
      active: false,
      completed: false,
    },
  ];

  const [options] = useState([
    { label: "Transferencia SPEI (Pesos Mexicanos)", value: "spei" },
    { label: "Transferencia criptomonedas (USDT)", value: "criptomonedas" },
  ]);
  
  const [cryptoOptions] = useState([
    { label: "Bitcoin (BTC)", value: "btc" },
    { label: "TRON TRX USDT", value: "tron" },
    { label: "USDT - Binance Smart Chain (BEP20)", value: "bsc" },
  ]);
  const [selectedCrypto, setSelectedCrypto] = useState("btc");
  const [selected, setSelected] = useState("spei");
  const api = process.env.REACT_APP_API_URL;
  const [solicitarAportacion, setSolicitarAportacion] = useState(false);

  const handleSolicitarAportacion = () => {
    try {
      const data = {
        monto: monto,
        metodo: selected
      }
      axios.post(api + "/etd/solicitar/aportacion", JSON.stringify(data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((res)=>{
        console.log(res)
        setSolicitarAportacion(true);
      })

    } catch (error) {
      console.log(error);
    }

    toaster.notify("Se ha solicitado correctamente la aportación");
  };


  

  const [monto, setMonto] = useState(0);

  return (
    <Pane>
      <Dialog
      topOffset={50}
        isShown={props.showAportaciones}
        title="Solicitar aportación"
        onCloseComplete={() => props.toggleAportaciones()}
        preventBodyScrolling
        footer={
          <Pane
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            padding={16}
          >
            <Button
              appearance="primary"
              intent="none"
              onClick={() => props.toggleAportaciones()}
            >
              Cancelar
            </Button>
          </Pane>
        }
      >
        <Pane display="flex" flexDirection="column" gap={20}>
          <Pane
            display="flex"
            flexDirection="row"
            height="100%"
            gap={20}
            elevation={1}
            padding={20}
            background="tint2"
          >
            <Pane>
              <TextInputField
                placeholder="Monto"
                type="number"
                label="Ingresa el monto de la aportación"
                value={monto}
                onChange={(e) => setMonto(e.target.value)}
              />
            </Pane>
            <Pane>
              <Paragraph>Selecciona el metodo de aportación</Paragraph>
              <RadioGroup
                
                options={options}
                value={selected}
                onChange={(e) => setSelected(e.target.value)}
              />
            </Pane>
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            alignItems="center"
            elevation={1}
            padding={20}
            background="tint2"
          >
            {selected === "spei" ? (
              <Pane display="flex" flexDirection="column" gap={20}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paragraph>Banco</Paragraph>
                  <Paragraph>Inbursa</Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paragraph>Nombre</Paragraph>
                  <Paragraph>VHM impuls SAPI de CV</Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paragraph>Numero de cuenta</Paragraph>
                  <Paragraph>50059381373</Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paragraph>Clabe interbancaria</Paragraph>
                  <Paragraph>03630500593813738</Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Paragraph>Monto</Paragraph>
                  <Paragraph>${monto}</Paragraph>
                </div>
              </Pane>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 20
                }}
              >
                <Pane width="100%" marginBottom={16}>
                  <Paragraph marginBottom={8}>Selecciona la red</Paragraph>
                  <RadioGroup
                    options={cryptoOptions}
                    value={selectedCrypto}
                    onChange={(e) => setSelectedCrypto(e.target.value)}
                  />
                </Pane>

                <Pane
                  display="flex"
                  flexDirection="column"
                  gap={10}
                  background="tint1"
                  padding={16}
                  borderRadius={8}
                  width="100%"
                  alignItems="center"
                >
                  {selectedCrypto === "btc" && (
                    <>
                      <Paragraph size={500} marginBottom={8}>Bitcoin (BTC)</Paragraph>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("1NgSqJjBzW7Z8YHgGM6Z1LzwgGVzvFtHgs");
                          toaster.success("Dirección Bitcoin copiada");
                        }}
                      >
                        <Paragraph>1NgSqJjBzW7Z8YHgGM6Z1LzwgGVzvFtHgs</Paragraph>
                      </div>
                      <img src={imgbtc} alt="QR Bitcoin" width={200} />
                    </>
                  )}

                  {selectedCrypto === "tron" && (
                    <>
                      <Paragraph size={500} marginBottom={8}>TRON TRX USDT</Paragraph>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("TADe8WMhi2XVRa7RxYHkwTzWkWDBsUDndK");
                          toaster.success("Dirección TRON copiada");
                        }}
                      >
                        <Paragraph>TADe8WMhi2XVRa7RxYHkwTzWkWDBsUDndK</Paragraph>
                      </div>
                    <img src={imgUSDT} alt="QR TRON" width={200} />
                    </>
                  )}

                  {selectedCrypto === "bsc" && (
                    <>
                      <Paragraph size={500} marginBottom={8}>USDT - Binance Smart Chain (BEP20)</Paragraph>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 5,
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("0xe5b45bad218cd39e7c2cac1c6db93f82de7539ea");
                          toaster.success("Dirección BSC copiada");
                        }}
                      >
                        <Paragraph>0xe5b45bad218cd39e7c2cac1c6db93f82de7539ea</Paragraph>
                      </div>
                      <img src={imgBEP20} alt="QR BSC" width={200} />
                    </>
                  )}

                  <Paragraph marginTop={16}>Monto</Paragraph>
                  <Paragraph>${monto}</Paragraph>
                </Pane>
              </div>
            )}
            <Button
              appearance="primary"
              intent="success"
              onClick={() => handleSolicitarAportacion()}
              marginTop={20}
            >
              Solicitar aportación
            </Button>
          </Pane>
        </Pane>
      </Dialog>
    </Pane>
  );
};

export default Aportaciones;