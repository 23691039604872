import React from "react";
import { useState, useEffect } from "react";
import {
  Combobox,
  Heading,
  Pane,
  TextInputField,
  Button,
  Table,
} from "evergreen-ui";
import { useFormik } from "formik";

const FormularioRetiros = (props) => {
  const mes = props.mes;
  const year = props.year; // Obtenemos el año de los props
  const retiros = props.retiros;
  
  const formik = useFormik({
    initialValues: {
      mes: "",
      monto: 0,
      year: year, // Inicializamos con el año recibido
      tipo: "",
    },
    onSubmit: props.onSubmit,
  });

  const [retiro, setRetiro] = useState({});
  const [total, setTotal] = useState(0);

  useEffect(() => {
    try {
      const mesId = getMesId(mes);
      // Filtramos por mes y año
      const retirosDelMes = retiros.filter((retiro) => {
        return retiro.mes === mesId && retiro.year === year;
      });
      setRetiro(retirosDelMes);
      formik.values.mes = mesId;
      formik.values.year = year; // Actualizamos el año en formik
      const suma = retirosDelMes.reduce((a, b) => a + b.monto, 0);
      setTotal(suma);
    } catch (error) {
      console.error(error);
    }
  }, [mes, year, retiros]); // Agregamos year como dependencia

  const getMesId = (mes) => {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    return meses.indexOf(mes).toString();
  };

  return (
    <Pane>
      {retiro.length > 0 ? (
        <Pane>
          <Heading size={600} marginTop={20}>
            Retiros del mes de {mes} {year} - Total: {"$"}{total}
          </Heading>
          <Pane display="flex" justifyContent="center">
            <Pane width="100%">
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Monto</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Tipo</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Fecha de registro</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                  {retiro.map((retiro) => (
                    <Table.Row key={retiro.id}>
                      <Table.TextCell>{"$"}{retiro.monto}</Table.TextCell>
                      <Table.TextCell>{retiro.tipo}</Table.TextCell>
                      <Table.TextCell>{retiro.fecha}</Table.TextCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

              <Pane display="flex" flexDirection="column" justifyContent="center">
                    <TextInputField
                      label="Monto"
                      name="monto"
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.monto}
                    />
                    <Combobox
                      items={["Extraordinario", "Ordinario"]}
                      placeholder="Tipo de retiro"
                      onChange={(selected) => {
                        formik.values.tipo = selected;
                      }}
                      width="100%"
                    />
                    <Button
                      type="submit"
                      appearance="primary"
                      intent="success"
                      marginTop={20}
                      onClick={() => {
                        console.log(formik.values);
                        props.uploadRetiro(formik.values);
                      }}
                    >
                      Agregar
                    </Button>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      ) : (
        <Pane>
          <Heading size={600} marginTop={20}>
            Retiros del mes de {mes} {year}
          </Heading>
          <Pane display="flex" justifyContent="center">
            <Pane width="100%">
              <Pane display="flex" justifyContent="center">
                <Pane width="100%">
                  <TextInputField
                    label="Monto"
                    name="monto"
                    type="number"
                    onChange={formik.handleChange}
                    value={formik.values.monto}
                    width="100%"
                  />
                  <Combobox
                    items={["Extraordinario", "Ordinario"]}
                    placeholder="Tipo de retiro"
                    onChange={(selected) => {
                      formik.values.tipo = selected;
                    }}
                    width="100%"
                  />

                  <Button
                    appearance="primary"
                    intent="success"
                    marginTop={20}
                    onClick={() => {
                      console.log(formik.values);
                      props.uploadRetiro(formik.values);
                    }}
                  >
                    Agregar
                  </Button>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      )}
    </Pane>
  );
};

export default FormularioRetiros;