import React from "react";
import { useState, useEffect } from "react";
import {
  Combobox,
  Heading,
  Pane,
  TextInputField,
  Button,
  toaster,
  Tablist,
  Tab,
} from "evergreen-ui";
import { useFormik } from "formik";

const FormularioUtilidades = (props) => {
  const mes = props.mes;
  const year = props.year; // Obtenemos el año de los props
  const utilidades = props.utilidades;

  const formik = useFormik({
    initialValues: {
      mes: "",
      porcentaje: 0,
      monto: 0,
      year: year, // Inicializamos con el año recibido
    },
    onSubmit: props.onSubmit,
  });

  const [utilidad, setUtilidad] = useState({});

  const getMesId = (mes) => {
    switch (mes) {
      case "Enero":
        return "0";
      case "Febrero":
        return "1";
      case "Marzo":
        return "2";
      case "Abril":
        return "3";
      case "Mayo":
        return "4";
      case "Junio":
        return "5";
      case "Julio":
        return "6";
      case "Agosto":
        return "7";
      case "Septiembre":
        return "8";
      case "Octubre":
        return "9";
      case "Noviembre":
        return "10";
      case "Diciembre":
        return "11";
      default:
        return "0";
    }
  };

  useEffect(() => {
    const mesId = getMesId(mes);
    // Filtramos por mes y año
    const utilidad = utilidades.find(
      (utilidad) => utilidad.mes === mesId && utilidad.year === year
    );
    setUtilidad(utilidad);
    formik.values.mes = mesId;
    formik.values.year = year; // Actualizamos el año en formik
  }, [mes, year, utilidades]); // Agregamos year como dependencia

  return (
    <Pane>
      {utilidad ? (
        <Pane>
          <TextInputField
            label="Porcentaje"
            name="porcentaje"
            value={utilidad.porcentaje}
            disabled
          />
          {utilidad.monto ? (
            <TextInputField
              label="Monto"
              name="monto"
              value={utilidad.monto.toFixed(2)}
              disabled
            />
          ) : (
            <TextInputField
              label="Monto"
              name="monto"
              value={utilidad.monto}
              disabled
            />
          )}
          <TextInputField
            label="Año"
            name="year"
            value={utilidad.year}
            disabled
          />
        </Pane>
      ) : (
        <Pane>
          <TextInputField
            label="Porcentaje"
            name="porcentaje"
            value={formik.values.porcentaje}
            onChange={formik.handleChange}
          />
          <TextInputField
            label="Monto"
            name="monto"
            value={formik.values.monto}
            onChange={formik.handleChange}
            disabled
          />
          <TextInputField
            label="Año"
            name="year"
            value={formik.values.year}
            onChange={formik.handleChange}
            disabled
          />
          <Button
            appearance="primary"
            intent="success"
            onClick={() => {
              console.log(formik.values);
              props.uploadUtilidad(formik.values);
            }}
          >
            Agregar Utilidad
          </Button>
        </Pane>
      )}
    </Pane>
  );
};

export default FormularioUtilidades;