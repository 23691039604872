import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Button, TextInputField, Pane, Combobox, Alert } from "evergreen-ui";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { AiOutlineSwap } from "react-icons/ai";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { AiOutlineBarChart } from "react-icons/ai";

import { AiOutlineMenu } from "react-icons/ai";
import { CiMoneyCheck1 } from "react-icons/ci";
import { CiSettings } from "react-icons/ci";
import { TiEdit } from "react-icons/ti";
import logo from "../../img/logo blanco.png";
import { BsShieldLock } from "react-icons/bs";
import { RiSecurePaymentFill } from "react-icons/ri";
import { BsFillPeopleFill } from "react-icons/bs";
import { CiMoneyBill } from "react-icons/ci";




import { Link } from "react-router-dom";
import { MdAddCard } from "react-icons/md";

const AdminSideMenu = (props) => {
  console.log(props.options);
  const [isMobile, setIsMobile] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        position: "fixed",
        zIndex: "1",
      }}
    >
      <Button
        onClick={() => {
          console.log("CLICK");
          setToggled(true);
        }}
        style={{
          zIndex: "4",
          position: "fixed",
          left: "0px",
          top: "0px",
          backgroundColor: "#1b1b1f",
          color: "#fff",
          border: "none",
          display: isMobile ? "block" : "none",
          width: "90px",
          height: "90px",
        }}
      >
        <AiOutlineMenu
          size={50}
          style={{
            margin: "auto",
            display: "block",
          }}
        />
      </Button>

      <Sidebar
        backgroundColor="#1b1b1f"
        toggled={toggled}
        breakPoint="md"
        onBackdropClick={() => setToggled(false)}
        style={{
          margin: 0,
          padding: 0,
          borderRight: "0px solid #333342",
        }}
      >
        <Menu
          menuItemStyles={{
            button: {
              color: "#fff",
              fontSize: "16px",
              fontWeight: "bold",
              margin: "auto",
            },
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              padding: "10px",
              margin: "auto",
              display: "block",
              marginBottom: "20px",
              marginTop: "20px",
              height: "auto",
              width: "180px",
            }}
          />

          <MenuItem component={<Link to="/" />}>Home</MenuItem>
          
          <SubMenu
            title="SPC-HLF"
            label="SPC-HLF"
            icon={<AiOutlineBarChart size={30} />}
            >
            <MenuItem component={<Link to="/rendimientosG" />}
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
            >
              Pagina principal
            </MenuItem>
          </SubMenu>

          
          <SubMenu title="ImpulsPay" label="ImpulsPay">
            <MenuItem
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
              icon={<CiMoneyCheck1 size={30} />}
              component={<Link to="/impulsPay" />}
            >
              Tarjetas
            </MenuItem>

            <MenuItem
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
              icon={<FaMoneyBillTransfer size={30} />}
              component={<Link to="/impulspay/solicitudes" />}
            >
              Recargas
            </MenuItem>
            <MenuItem
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
              icon={<MdAddCard size={30} />}
              component={<Link to="/impulspay/tarjetasnuevas" />}
            >
              Nuevas tarjetas
            </MenuItem>
            
            <MenuItem
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
              icon={<RiSecurePaymentFill size={30} />}
              component={<Link to="/impulspay/tpv" />}
            >
              TPV Impulspay
            </MenuItem>
            <MenuItem
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
              icon={<CiMoneyBill size={30} />}
              component={<Link to="/impulspay/tpv/pagos" />}
            >
              Pagos TPV
            </MenuItem>
            <MenuItem
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
              icon={<CiSettings size={30} />}
              component={<Link to="/impulspay/settings" />}
            >
              Configuración
            </MenuItem>
          </SubMenu>
          <SubMenu
            title="Protecc"
            label="Protecc"
            icon={<BsShieldLock size={30} />}
            
          >
            <MenuItem
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
              icon={<TiEdit size={30} />}
              component={<Link to="/protecc" />}
            >
              Seguros
            </MenuItem>
            <MenuItem
              icon={<BsFillPeopleFill size={25} />}
              label="Administrar Membresía de Salud"
              component={<Link to="/admin-salud" />}
              style={{
                color: "#fff",
                backgroundColor: "#333342",
              }}
            >
              Administrar Membresías de Salud
            </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default AdminSideMenu;
