import React, { useState, useEffect } from "react";
import {
  Pane,
  Heading,
  Button,
  Table,
  toaster,
  Dialog,
  Paragraph,
} from "evergreen-ui";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const AdminIMSSPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = location.state;

  const [empleados, setEmpleados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmpleado, setSelectedEmpleado] = useState(null);
  
  // Estados para los diálogos de confirmación
  const [isAutorizarDialogOpen, setIsAutorizarDialogOpen] = useState(false);
  const [isEliminarDialogOpen, setIsEliminarDialogOpen] = useState(false);
  const [empleadoToAction, setEmpleadoToAction] = useState(null);

  const fetchEmpleados = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/users/${user._id}/empleados`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (Array.isArray(res.data.empleados)) {
          setEmpleados(res.data.empleados);
        } else {
          console.error("Los empleados no se devolvieron como un array");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al cargar empleados:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEmpleados();
  }, [user._id]);

  // Funciones para manejar la apertura de diálogos de confirmación
  const handleAutorizarClick = (empleado) => {
    setEmpleadoToAction(empleado);
    setIsAutorizarDialogOpen(true);
  };

  const handleEliminarClick = (empleado) => {
    setEmpleadoToAction(empleado);
    setIsEliminarDialogOpen(true);
  };

  // Funciones de acción con confirmación
  const handleAutorizarEmpleado = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/users/${user._id}/empleados/${empleadoToAction._id}/autorizar`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then(() => {
        toaster.success("Empleado autorizado exitosamente.");
        setIsAutorizarDialogOpen(false);
        fetchEmpleados();
      })
      .catch((error) => {
        console.error("Error al autorizar el empleado:", error);
        toaster.danger("Error al autorizar el empleado.");
      });
  };

  const handleEliminarEmpleado = () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/users/${user._id}/empleados/${empleadoToAction._id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then(() => {
        toaster.notify("Empleado eliminado exitosamente.");
        setIsEliminarDialogOpen(false);
        fetchEmpleados();
      })
      .catch((error) => {
        console.error("Error al eliminar el empleado:", error);
        toaster.danger("Error al eliminar el empleado.");
      });
  };

  const handleVerDetalle = (empleado) => {
    setSelectedEmpleado(empleado);
    setIsModalOpen(true);
  };

  return (
    <motion.div
      key={"seguros"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Pane
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={40}
        color="white"
        zIndex={1}
        className="imss-header"
      >
        <Heading size={800} color="white">
          Membresía de Salud
        </Heading>
      </Pane>
      <Pane
        padding={40}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Heading size={800} color="white">
          Membresía de Salud - Administración
        </Heading>

        <Button
          marginTop={20}
          onClick={() => navigate("/admin-salud")}
          intent="none"
          appearance="primary"
        >
          Volver
        </Button>

        <Table width="100%" maxWidth="1400px" marginTop={20}>
          <Table.Head>
            <Table.TextHeaderCell>Nombre</Table.TextHeaderCell>
            <Table.TextHeaderCell>NSS</Table.TextHeaderCell>
            <Table.TextHeaderCell>RFC</Table.TextHeaderCell>
            <Table.TextHeaderCell>Estado de suscripción</Table.TextHeaderCell>
            <Table.TextHeaderCell flexBasis={200}>
              Acciones
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {empleados.map((empleado) => (
              <Table.Row key={empleado._id}>
                <Table.TextCell>{empleado.nombre}</Table.TextCell>
                <Table.TextCell>{empleado.nss}</Table.TextCell>
                <Table.TextCell>{empleado.rfc}</Table.TextCell>
                <Table.TextCell>{empleado.estadoSuscripcion}</Table.TextCell>
                <Table.TextCell flexBasis={200}>
                  {!empleado.autorizado ? (
                    <Button
                      onClick={() => handleAutorizarClick(empleado)}
                      intent="success"
                      marginRight={8}
                    >
                      Autorizar
                    </Button>
                  ) : (
                    <Button
                      disabled
                      intent="success"
                      marginRight={8}
                    >
                      Autorizado
                    </Button>
                  )}
                  <Button
                    onClick={() => handleEliminarClick(empleado)}
                    intent="danger"
                    marginRight={8}
                  >
                    Eliminar
                  </Button>
                  <Button
                    onClick={() => handleVerDetalle(empleado)}
                    intent="none"
                    appearance="primary"
                  >
                    Ver Detalle
                  </Button>
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {/* Modal para ver detalle del empleado */}
        {selectedEmpleado && (
          <Dialog
            isShown={isModalOpen}
            title="Detalle del Empleado"
            onCloseComplete={() => setIsModalOpen(false)}
            hasFooter={false}
          >
            <Pane>
              <Paragraph>
                <strong>Nombre:</strong> {selectedEmpleado.nombre}
              </Paragraph>
              <Paragraph>
                <strong>NSS:</strong> {selectedEmpleado.nss}
              </Paragraph>
              <Paragraph>
                <strong>RFC:</strong> {selectedEmpleado.rfc}
              </Paragraph>
              <Paragraph>
                <strong>Estado de suscripción:</strong>{" "}
                {selectedEmpleado.estadoSuscripcion}
              </Paragraph>
              <Paragraph>
                <strong>Teléfono:</strong> {selectedEmpleado.telefono}
              </Paragraph>
              <Paragraph>
                <strong>Email:</strong> {selectedEmpleado.correo}
              </Paragraph>
            </Pane>
          </Dialog>
        )}

        {/* Diálogo de confirmación para autorizar */}
        <Dialog
          isShown={isAutorizarDialogOpen}
          title="Confirmar Autorización"
          intent="success"
          onCloseComplete={() => setIsAutorizarDialogOpen(false)}
          confirmLabel="Autorizar"
          cancelLabel="Cancelar"
          onConfirm={handleAutorizarEmpleado}
        >
          ¿Está seguro que desea autorizar al empleado {empleadoToAction?.nombre}?
          <Paragraph marginTop={8}>
            Esta acción permitirá que el empleado acceda a los beneficios de la membresía de salud.
          </Paragraph>
        </Dialog>

        {/* Diálogo de confirmación para eliminar */}
        <Dialog
          isShown={isEliminarDialogOpen}
          title="Confirmar Eliminación"
          intent="danger"
          onCloseComplete={() => setIsEliminarDialogOpen(false)}
          confirmLabel="Eliminar"
          cancelLabel="Cancelar"
          onConfirm={handleEliminarEmpleado}
        >
          ¿Está seguro que desea eliminar al empleado {empleadoToAction?.nombre}?
          <Paragraph marginTop={8}>
            Esta acción no se puede deshacer y el empleado perderá acceso a todos los beneficios.
          </Paragraph>
        </Dialog>
      </Pane>
    </motion.div>
  );
};

export default AdminIMSSPage;