import React, { useState, useEffect } from "react";
import { Pane, Heading, Button, Table, toaster } from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";

const UsuariosListPage = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(process.env.REACT_APP_API_URL + "/users/")
      .then((res) => {
        const usuariosNoAdmin = res.data.filter((usuario) => usuario.role !== "admin");
        setUsuarios(usuariosNoAdmin);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error al cargar los usuarios:", error);
        toaster.danger("No se pudo cargar la lista de usuarios.");
        setIsLoading(false);
      });
  }, []);

  const handleSeleccionarUsuario = (usuario) => {
    navigate(`/admin-salud/${usuario._id}`, { state: { user: usuario } });
  };

  const usuariosFiltrados = usuarios.filter((usuario) =>
    usuario.nombre.toLowerCase().includes(searchQuery.toLowerCase()) ||
    usuario.correo.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <motion.div
      key="seguros"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {/* Header Section */}
      <Pane
        display="flex"
        justifyContent="center"
        flexDirection="column"
        padding={40}
        color="white"
        zIndex={1}
        className="imss-header"
      >
        <Heading size={800} color="white">
          Membresías de salud
        </Heading>
      </Pane>

      {/* Content Section */}
      <Pane 
        padding={40} 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        background="#1a1a1a"
        minHeight="calc(100vh - 200px)"
      >
        <Heading size={800} color="white" marginBottom={20}>
          Lista de Usuarios
        </Heading>

        <Table 
          width="100%" 
          maxWidth="1000px" 
          backgroundColor="#333342" 
          borderRadius={8}
        >
          <Table.Head>
            <Table.SearchHeaderCell
              placeholder="Buscar por nombre o correo"
              onChange={(value) => setSearchQuery(value)}
              color="#e1e4f4"
            />
            <Table.TextHeaderCell color="black">Email</Table.TextHeaderCell>
            <Table.TextHeaderCell color="black">Acciones</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {usuariosFiltrados.length === 0 ? (
              <Table.Row>
                <Table.TextCell colSpan="3" textAlign="center" color="#e1e4f4">
                  No se encontraron usuarios
                </Table.TextCell>
              </Table.Row>
            ) : (
              usuariosFiltrados.map((usuario) => (
                <Table.Row 
                  key={usuario._id}
                  height={64}
                >
                  <Table.TextCell color="#e1e4f4">
                    <Pane display="flex" alignItems="center">
                      {usuario.nombre}
                    </Pane>
                  </Table.TextCell>
                  <Table.TextCell color="#e1e4f4">{usuario.correo}</Table.TextCell>
                  <Table.TextCell>
                    <Button
                      onClick={() => handleSeleccionarUsuario(usuario)}
                      intent="success"
                      appearance="primary"
                      backgroundColor="#00b383"
                    >
                      Administrar Empleados
                    </Button>
                  </Table.TextCell>
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </Pane>
    </motion.div>
  );
};

export default UsuariosListPage;