import React, { useEffect } from "react";
import { useState } from "react";
import Axios from "axios";
import {
  Combobox,
  Heading,
  Pane,
  TextInputField,
  Button,
  toaster,
  Tablist,
  Tab,
  Switch,
  Text,
  Label,
} from "evergreen-ui";

import { useFormik } from "formik";
import FormularioUtilidades from "../utilidades";
import FormularioAportaciones from "./aportaciones";
import FormularioRetiros from "./retiros";

const AdminETD = () => {
  const formik = useFormik({
    initialValues: {
      contratopdf: "",
      status: "",
      balance: 0,
      balance_inicial: 0,
    },
    onSubmit: (values) => {
      //add checked to the values
      values.status = checked;
      Axios.put(
        process.env.REACT_APP_API_URL+"/etd/" + selectedContract._id,
        JSON.stringify(values),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          toaster.success("Contrato actualizado");
          updateUsers();
        })
        .catch((err) => {
          toaster.danger("Error al actualizar el contrato");
        });
    },
  });

  const uploadUtilidad = (values) => {
    console.log("values", values);
    console.log("selectedContract", selectedContract._id);
    Axios.post(
      process.env.REACT_APP_API_URL+"/etd/utilidad/" + selectedContract._id,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        toaster.success("Utilidad agregada");
        updateUsers();
      })
      .catch((err) => {
        toaster.danger("Error al agregar la utilidad");
      });
  };

  const uploadAportacion = (values) => {
    console.log("values", values);
    console.log("selectedContract", selectedContract._id);
    Axios.post(
      process.env.REACT_APP_API_URL+"/etd/aportacion/" + selectedContract._id,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        toaster.success("Aportacion agregada");
        updateUsers();
      })
      .catch((err) => {
        toaster.danger("Error al agregar la aportacion");
      });
  };

  const uploadRetiro = (values) => {
    console.log("values", values);
    console.log("selectedContract", selectedContract._id);
    Axios.post(
      process.env.REACT_APP_API_URL+"/etd/retiro/" + selectedContract._id,
      JSON.stringify(values),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        toaster.success("Retiro agregado");
        updateUsers();
      })
      .catch((err) => {
        toaster.danger("Error al agregar el retiro");
      });
  };

  const [selectedTab, setSelectedTab] = useState("Contrato");
  const [checked, setChecked] = useState(false);
  const [tabs] = useState([
    "Contrato",
    "Rendimientos",
    "Aportaciones",
    "Retiros",
  ]);

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const [dbUsuarios, setDbUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nombre, setNombre] = useState("");
  const [selectedContract, setSelectedContract] = useState(null);
  const [mes, setMes] = useState("Enero");

  const updateUsers = () => {
    Axios.get(process.env.REACT_APP_API_URL + "/etd").then((res) => {
      console.log(res);
      setDbUsuarios(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    console.log("Admin ETD");
    setLoading(true);
    updateUsers();
  }, []);

  useEffect(() => {
    console.log("nombre", nombre);
    const selectedContract = dbUsuarios.find(
      (etdContrato) => etdContrato.user.nombre === nombre
    );
    setSelectedContract(selectedContract);
    console.log("selectedContract", selectedContract);
  }, [nombre, dbUsuarios]);

  useEffect(() => {
    console.log("selectedContract", selectedContract);
    if (selectedContract) {
      formik.setValues({
        contratopdf: selectedContract.contratopdf,
        status: selectedContract.status,
        balance: selectedContract.balance,
        balance_inicial: selectedContract.balance_inicial,
      });
      setChecked(selectedContract.status);
    }
  }, [selectedContract]);

  const handleSelectContract = (e) => {
    const nombre = e;
    setNombre(nombre);
  };

  const handleSelectMonth = (e) => {
    const mes = e;
    setMes(mes);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-xl text-gray-200">Cargando...</div>
      </div>
    );
  } else {
    return (
      <div
        className="min-h-screen bg-gray-900 text-gray-100 p-6"
      >
        <Pane>
          <Heading size={900}>Administrar SPC-HLF</Heading>
          <Combobox
            items={dbUsuarios.map((etdContrato) => etdContrato.user.nombre)}
            placeholder="Selecciona un usuario"
            key={dbUsuarios._id}
            onChange={handleSelectContract}
          />

          <Pane
            display="flex"
            flexDirection="column"
            style={{
              width: "500px",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            {
              //Agregar campos editables con los valores obtenidos de la base de datos

              selectedContract ? (
                <div>
                  <Heading size={1500}>
                    {selectedContract.user.nombre}{" "}
                    {selectedContract.user.apellido}
                  </Heading>

                  <Tablist
                    marginBottom={16}
                    marginTop={16}
                    marginRight={24}
                    width="100%"
                    flexBasis={240}
                  >
                    {tabs.map((tab, index) => (
                      <Tab
                        key={tab}
                        id={tab}
                        onSelect={() => setSelectedTab(tab)}
                        isSelected={tab === selectedTab}
                        aria-controls={`panel-${tab}`}
                      >
                        {tab}
                      </Tab>
                    ))}
                  </Tablist>
                  <Pane
                    padding={16}
                    background="tint2"
                    elevation={1}
                    style={{
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Heading size={900} fontWeight={200} alignSelf="center">
                      {selectedTab}
                    </Heading>
                    <hr />
                    {selectedTab === "Contrato" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                        }}
                      >
                        <form onSubmit={formik.handleSubmit}>
                          <TextInputField
                            id="contratopdf"
                            name="contratopdf"
                            label="Contrato"
                            value={formik.values.contratopdf}
                            onChange={formik.handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "40px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Label>Estado</Label>
                            <Switch
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                              name="status"
                            />
                          </div>
                          <TextInputField
                            id="balance"
                            name="balance"
                            label="Balance"
                            disabled
                            value={formik.values.balance.toFixed(2)}
                            onChange={formik.handleChange}
                          />
                          <TextInputField
                            id="balance_inicial"
                            name="balance_inicial"
                            label="Balance inicial"
                            value={formik.values.balance_inicial}
                            onChange={formik.handleChange}
                          />
                          <TextInputField
                            label="Fecha de creacion"
                            value={selectedContract.createdAt}
                            onChange={formik.handleChange}
                            disabled
                          />
                          <Button appearance="primary" type="submit">
                            Guardar
                          </Button>
                        </form>
                      </div>
                    ) : selectedTab === "Rendimientos" ? (
                      <div>
                        <Pane flexDirection="column" gap={20}>
                          <Combobox
                            items={meses}
                            placeholder="Selecciona un mes"
                            key={meses._id}
                            value={mes}
                            onChange={handleSelectMonth}
                            marginBottom={20}
                            width="100%"
                          />
                          <FormularioUtilidades
                            utilidades={selectedContract.utilidades}
                            mes={mes}
                            uploadUtilidad={uploadUtilidad}
                          />
                        </Pane>
                      </div>
                    ) : selectedTab === "Aportaciones" ? (
                      <div>
                        <Pane flexDirection="column">
                          <Combobox
                            items={meses}
                            placeholder="Selecciona un mes"
                            key={meses._id}
                            value={mes}
                            onChange={handleSelectMonth}
                            marginBottom={20}
                            width="100%"
                          />
                          <FormularioAportaciones
                            aportaciones={selectedContract.aportaciones}
                            mes={mes}
                            uploadAportacion={uploadAportacion}
                          />
                        </Pane>
                      </div>
                    ) : selectedTab === "Retiros" ? (
                      <div>
                        <Pane flexDirection="column">
                          <Combobox
                            items={meses}
                            placeholder="Selecciona un mes"
                            key={meses._id}
                            value={mes}
                            onChange={handleSelectMonth}
                            marginBottom={20}
                            width="100%"
                          />
                          <FormularioRetiros
                            retiros={selectedContract.retiros}
                            mes={mes}
                            uploadRetiro={uploadRetiro}
                          />
                        </Pane>
                      </div>
                    ) : null}
                  </Pane>
                </div>
              ) : null
            }
          </Pane>
        </Pane>
      </div>
    );
  }
};

export default AdminETD;
