import React from "react";
import { useState, useEffect } from "react";
import {
  Combobox,
  Heading,
  Pane,
  TextInputField,
  Button,
  toaster,
  Tablist,
  Paragraph,
  Tab,
  SearchInput,
  Table,
} from "evergreen-ui";
import { useFormik } from "formik";
import axios from "axios";
import { initAxiosInterceptors } from "../../../../util/authHelper";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ZAxis,
  ComposedChart,
  Bar,
  BarChart,
  AreaChart,
  ResponsiveContainer,
  Area,
} from "recharts";
import AportacionesYRetiros from "../../../etd/graficas/AportacionesRetiros";
import Gestion from "./gestion";
import RendimientoGeneral from "./rendimientogeneral";
import Historicos from "./Historicos";

initAxiosInterceptors();

const RendimientosGlobales = (props) => {
  const api = process.env.REACT_APP_API_URL;

  const [allETD, setAllETD] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mes, setMes] = useState("Enero");
  const [user, setUser] = useState(0);
  const [utilidades, setUtilidades] = useState([]);
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2023");
  const [selectedETD, setSelectedETD] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const handleChange = (e) => {
    setSelectedYear(e);
  };

  useEffect(() => {
    const toSearch = allETD;
    const usersSearch = toSearch.filter((user) => {
      if (user.nombre.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (user.apellido.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (user.correo.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      return false;
    });
    setFilteredUsers(usersSearch);
  }, [search]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    const filtered = utilidades
      .reduce((acc, item) => {
        if (item.year === selectedYear) {
          const newItem = {
            ...item,
            name: meses[item.mes],
            Monto: item.monto.toFixed(2),
            Porcentaje: item.porcentaje.toFixed(2),
          };
          acc.push(newItem);
        }
        return acc;
      }, [])
      .sort((a, b) => a.mes - b.mes);
    setData(filtered);
  }, [selectedYear, utilidades]);

  const getAllETD = async () => {
    try {
      const res = await axios.get(`${api}/etd`);
      const datos = res.data;
      const filteredDatos = [];
      console.log(datos)
      for (let i = 0; i < datos.length; i++) {
        if (datos[i].user.role !== 'admin') {
          datos[i].nombre = datos[i].user.nombre;
          datos[i].apellido = datos[i].user.apellido;
          datos[i].correo = datos[i].user.correo;
          filteredDatos.push(datos[i]);
        }
      }
  
      setAllETD(filteredDatos);
      setLoading(false);
      setFilteredUsers(filteredDatos);
      // Solo establecemos selectedETD si hay datos filtrados
      if (filteredDatos.length > 0) {
        setSelectedETD(filteredDatos[0]); // Seleccionamos el primer usuario no admin
        setUtilidades(filteredDatos[0].utilidades);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getAllETD();
  }, []);

  if (loading) {
    return (
      <div>
        <Heading size={700} marginTop={20} marginBottom={20}>
          Cargando...
        </Heading>
      </div>
    );
  }

  if (allETD.length === 0) {
    return (
      <div>
        <Heading size={700} marginTop={20} marginBottom={20}>
          No hay ETD registrados
        </Heading>
      </div>
    );
  }

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Heading
          size={1200}
          marginTop={20}
          marginBottom={20}
          fontVariant="simplified"
          color="whitesmoke"
        >
          Panel de administracion de SPC-HLF
        </Heading>
        
        <Pane
          display="grid"
          gridTemplateColumns="1.5fr 4fr 2fr"
          gridGap={20}
          width="100%"
          height="calc(100vh - 100px)"
          padding="10px"
        >
          {/* Panel izquierdo - Lista de usuarios */}
          <Pane 
            display="flex" 
            flexDirection="column" 
            height="100%"
            style={{ overflow: "hidden" }}
          >
            <Pane marginBottom={20}>
              <RendimientoGeneral updateFunc={getAllETD} />
            </Pane>

            <Pane
              background="tint2"
              display="flex"
              flexDirection="column"
              elevation={1}
              padding={20}
              height="100%"
              style={{ overflow: "hidden" }}
            >
              <Heading size={700} marginBottom={20} fontVariant="simplified">
                Usuarios
              </Heading>
              
              <SearchInput
                placeholder="Buscar usuario"
                onChange={(e) => setSearch(e.target.value)}
                marginBottom={20}
                width="100%"
              />

              <Pane
                flex={1}
                overflow="auto"
                style={{
                  paddingRight: "10px",
                }}
              >
                {filteredUsers.map((etd) => (
                  <Pane
                    key={etd.id_usuario}
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    marginBottom={10}
                    style={{
                      cursor: "pointer",
                      border: "1px solid #E4E7EB",
                      borderRadius: "3px",
                      padding: "10px",
                    }}
                    background={
                      selectedETD.id_usuario === etd.id_usuario
                        ? "white"
                        : "tint2"
                    }
                    onClick={() => {
                      setUtilidades(etd.utilidades);
                      setSelectedETD(etd);
                      setUser(filteredUsers.indexOf(etd));
                    }}
                  >
                    <Heading size={100}>{etd.id_usuario}</Heading>
                    <Heading size={400}>
                      {etd.nombre} {etd.apellido}
                    </Heading>
                    <Heading size={300}>{etd.correo}</Heading>
                  </Pane>
                ))}
              </Pane>
            </Pane>
          </Pane>

          {/* Panel central - Gráficas y datos */}
          <Pane
            background="tint2"
            elevation={1}
            height="100%"
            style={{ overflow: "hidden" }}
          >
            <Pane
              padding={20}
              height="100%"
              overflow="auto"
            >
              <Pane marginBottom={20} textAlign="center">
                <Heading>
                  {selectedETD.nombre} {selectedETD.apellido}
                </Heading>
                <Heading size={300}>{selectedETD.correo}</Heading>
                <Heading
                  size={900}
                  color={selectedETD.balance < 0 ? "red" : "green"}
                >
                  {formatter.format(selectedETD.balance)}
                </Heading>
              </Pane>

              <Heading size={1200} marginTop={20} marginBottom={20}>
                Rendimientos
              </Heading>

              <Pane marginBottom={20}>
                <Paragraph marginBottom={10}>
                  Selecciona el año que deseas ver:
                </Paragraph>
                <Combobox
                  items={["2021", "2022", "2023", "2024", "2025"]}
                  onChange={handleChange}
                  initialSelectedItem="2023"
                  width={200}
                />
              </Pane>

              <Pane marginBottom={40}>
                {data.length <= 0 ? (
                  <Heading size={300}>
                    No hay datos para mostrar en este momento
                  </Heading>
                ) : (
                  <>
                    <ResponsiveContainer height={200} width="100%">
                      <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis
                          dataKey="Monto"
                          tickFormatter={(label) => `$${label}`}
                        />
                        <Tooltip />
                        <Legend />
                        <Bar
                          type="monotone"
                          dataKey="Monto"
                          fill="#8884d8"
                          barSize={20}
                        />
                      </BarChart>
                    </ResponsiveContainer>

                    <ResponsiveContainer height={200} width="100%">
                      <AreaChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis
                          dataKey="Porcentaje"
                          tickFormatter={(label) => `${label}%`}
                        />
                        <Tooltip />
                        <Legend />
                        <Area
                          type="monotone"
                          dataKey="Porcentaje"
                          stroke="#82ca9d"
                          fill="#8884d8"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </>
                )}
              </Pane>

              <Pane marginBottom={40}>
                <Heading size={600} marginBottom={10}>
                  Aportaciones y retiros
                </Heading>
                <Paragraph marginBottom={20}>
                  En esta sección podrás ver el monto de tus aportaciones y
                  retiros mensuales.
                </Paragraph>
                {data ? (
                  <AportacionesYRetiros data={selectedETD} />
                ) : (
                  <Heading size={300}>
                    No hay datos para mostrar en este momento
                  </Heading>
                )}
              </Pane>

              <Pane>
                <Heading size={600} marginBottom={10}>
                  Registros históricos
                </Heading>
                <Paragraph marginBottom={20}>
                  En esta sección podrás ver el historial de rendimientos de
                  cada usuario.
                </Paragraph>
                <Historicos data={selectedETD} />
              </Pane>
            </Pane>
          </Pane>

          {/* Panel derecho - Gestión */}
          <Pane
            height="100%"
            style={{ overflow: "auto" }}
          >
            <Gestion
              selectedContract={selectedETD}
              updateFunc={getAllETD}
              year={selectedYear}
            />
          </Pane>
        </Pane>
      </Pane>
    </div>
  );
};

export default RendimientosGlobales;