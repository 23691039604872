import React, { useEffect, useState } from "react";
import {
  Button,
  Pane,
  Heading,
  TextInputField,
  Spinner,
  toaster,
  Card,
  IconButton,
  RefreshIcon,
  EmptyState,
  PersonIcon,
  SearchInput,
  defaultTheme,
  ThemeProvider,
  mergeTheme
} from "evergreen-ui";
import axios from "axios";
import { initAxiosInterceptors } from "../../util/authHelper";
import UserDetail from "./users/UserDetail";

initAxiosInterceptors();

// Tema oscuro personalizado
const darkTheme = mergeTheme(defaultTheme, {
  colors: {
    label: {
      color: '#ffffff'
    },
    background: {
      tint1: '#1a1a1a',
      tint2: '#2d2d2d',
      overlay: '#2d2d2d',
      yellowTint: '#2d2d2d',
      greenTint: '#2d2d2d',
      orangeTint: '#2d2d2d',
      redTint: '#2d2d2d',
      blueTint: '#2d2d2d',
      purpleTint: '#2d2d2d',
      tealTint: '#2d2d2d',
      neutralTint: '#2d2d2d'
    },
    text: {
      default: '#ffffff',
      dark: '#ffffff',
      muted: '#a1a1a1',
      selected: '#ffffff',
      success: '#ffffff',
      info: '#ffffff',
      danger: '#ffffff',
      warning: '#ffffff'
    },
    icon: {
      default: '#ffffff',
      muted: '#a1a1a1',
      selected: '#ffffff',
      success: '#ffffff',
      info: '#ffffff',
      danger: '#ffffff',
      warning: '#ffffff'
    },
    intent: {
      none: '#ffffff'
    }
  },
  components: {
    Card: {
      backgroundColor: '#2d2d2d'
    },
    Input: {
      backgroundColor: '#1a1a1a',
      color: '#ffffff',
      placeholderColor: '#a1a1a1'
    },
    SearchInput: {
      backgroundColor: '#1a1a1a',
      color: '#ffffff',
      placeholderColor: '#a1a1a1'
    },
    Label: {
      color: '#ffffff'
    },
    TextInputField: {
      labelColor: '#ffffff'
    }
  }
});

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await axios.get(process.env.REACT_APP_API_URL + "/users");
      const users = res.data;
      const justUsers = users.filter((user) => user.role !== "admin");
      setAllUsers(justUsers);
      setUsers(justUsers);
      toaster.success('Usuarios cargados correctamente', {
        backgroundColor: '#2d2d2d',
        color: '#ffffff'
      });
    } catch (error) {
      toaster.danger('Error al cargar los usuarios', {
        backgroundColor: '#2d2d2d',
        color: '#ffffff'
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const searchTerm = search.toLowerCase();
    const filteredUsers = allUsers.filter((user) => {
      return (
        (user.nombre?.toLowerCase().includes(searchTerm)) ||
        (user.apellido?.toLowerCase().includes(searchTerm)) ||
        (user.correo?.toLowerCase().includes(searchTerm))
      );
    });
    setUsers(filteredUsers);
  }, [search, allUsers]);

  const handleRefresh = () => {
    fetchData();
  };

  const renderUserCard = (user) => (
    <Card
      key={user._id}
      backgroundColor={selectedUser?._id === user._id ? '#2c5282' : '#2d2d2d'}
      padding={16}
      marginY={8}
      elevation={1}
      hoverElevation={2}
      cursor="pointer"
      onClick={() => setSelectedUser(user)}
    >
      <Pane display="flex" alignItems="center" justifyContent="space-between">
        <Pane>
          <Heading size={500} color="white">
            {user.nombre} {user.apellido}
          </Heading>
          <Heading size={300} color="#a1a1a1">
            {user.correo}
          </Heading>
        </Pane>
      </Pane>
    </Card>
  );

  return (
    <ThemeProvider value={darkTheme}>
      <Pane padding={24} background="#1a1a1a" minHeight="100vh">
        <Pane maxWidth={1200} marginLeft="auto" marginRight="auto">
          {/* Header */}
          <Pane 
            display="flex" 
            alignItems="center" 
            justifyContent="space-between"
            marginBottom={24}
          >
            <Pane>
              <Heading size={900} marginBottom={8} color="white">
                Panel de Administración
              </Heading>
              <Heading size={400} color="#a1a1a1">
                Gestiona los usuarios del sistema
              </Heading>
            </Pane>
            <IconButton
              icon={RefreshIcon}
              appearance="minimal"
              onClick={handleRefresh}
              isLoading={loading}
              iconColor="white"
            />
          </Pane>

          <Pane display="flex">
            {/* Left Panel - Users List */}
            <Pane width={400} flexShrink={0} marginRight={24}>
              <Card
                background="#2d2d2d"
                padding={16}
                elevation={1}
              >
                {/* Search */}
                <Pane marginBottom={16}>
                  <SearchInput
                    placeholder="Buscar usuarios..."
                    width="100%"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    background="#1a1a1a"
                    color="white"
                  />
                </Pane>

                {/* Users List */}
                <Pane
                  height="calc(100vh - 300px)"
                  overflowY="auto"
                  marginRight={-16}
                  paddingRight={16}
                >
                  {loading ? (
                    <Pane
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                    >
                      <Spinner />
                    </Pane>
                  ) : users.length === 0 ? (
                    <EmptyState
                      title="No hay usuarios"
                      icon={<PersonIcon color="white" />}
                      description={
                        search
                          ? "No se encontraron usuarios con tu búsqueda"
                          : "No hay usuarios registrados en el sistema"
                      }
                      background="transparent"
                      titleColor="white"
                      descriptionColor="#a1a1a1"
                    />
                  ) : (
                    users.map(renderUserCard)
                  )}
                </Pane>
              </Card>
            </Pane>

            {/* Right Panel - User Details */}
            <Pane flex={1}>
              <Card
                background="#2d2d2d"
                padding={24}
                elevation={1}
                height="calc(100vh - 140px)"
              >
                {selectedUser ? (
                  <UserDetail user={selectedUser} updateUsers={fetchData} />
                ) : (
                  <EmptyState
                    title="Selecciona un usuario"
                    icon={<PersonIcon color="white" />}
                    description="Selecciona un usuario de la lista para ver y editar sus detalles"
                    background="transparent"
                    titleColor="white"
                    descriptionColor="#a1a1a1"
                  />
                )}
              </Card>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </ThemeProvider>
  );
};

export default AdminUsers;