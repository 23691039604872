import React, { useEffect } from "react";
import { Heading, Pane, Button, toaster, Card, Text, TextInput } from "evergreen-ui";
import axios from "axios";
import { initAxiosInterceptors } from "../../../util/authHelper";

initAxiosInterceptors();

const UserDetail = ({ user, updateUsers }) => {
  const [selectedUser, setSelectedUser] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setSelectedUser(user);
  }, [user]);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const res = await axios.put(
        process.env.REACT_APP_API_URL + "/users/" + selectedUser._id,
        JSON.stringify(selectedUser),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      
      toaster.success("Usuario actualizado", {
        backgroundColor: '#2d2d2d',
        color: '#ffffff'
      });
      updateUsers();
    } catch (error) {
      toaster.danger("Error al actualizar usuario", {
        backgroundColor: '#2d2d2d',
        color: '#ffffff'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const InputGroup = ({ label, value, onChange, type = "text", placeholder }) => (
    <Pane marginBottom={16}>
      <Text color="white" fontWeight={500} marginBottom={8} display="block">
        {label}
      </Text>
      <TextInput
        placeholder={placeholder}
        value={value || ''}
        type={type}
        onChange={onChange}
        width="100%"
        height={40}
        background="#2d2d2d"
        color="white"
        placeholderTextColor="#a1a1a1"
        borderColor="#3d3d3d"
      />
    </Pane>
  );

  return (
    <Pane height="100%">
      <Heading 
        size={700} 
        marginBottom={24}
        color="white"
      >
        Detalles del Usuario
      </Heading>

      <Card
        background="#1a1a1a"
        padding={24}
        borderRadius={8}
        elevation={0}
      >
        <Pane>
          <InputGroup
            label="Nombre"
            placeholder="Ingresa el nombre"
            value={selectedUser.nombre}
            onChange={e => setSelectedUser({ ...selectedUser, nombre: e.target.value })}
          />
          
          <InputGroup
            label="Apellido"
            placeholder="Ingresa el apellido"
            value={selectedUser.apellido}
            onChange={e => setSelectedUser({ ...selectedUser, apellido: e.target.value })}
          />

          <InputGroup
            label="Correo electrónico"
            placeholder="Ingresa el correo"
            value={selectedUser.correo}
            onChange={e => setSelectedUser({ ...selectedUser, correo: e.target.value })}
          />

          <InputGroup
            label="Rol"
            placeholder="Ingresa el rol"
            value={selectedUser.role}
            onChange={e => setSelectedUser({ ...selectedUser, role: e.target.value })}
          />

          <Button
            onClick={handleSave}
            appearance="primary"
            intent="success"
            width="100%"
            height={40}
            isLoading={isLoading}
            marginTop={8}
            disabled
          >
            Guardar Cambios
          </Button>
        </Pane>
      </Card>
    </Pane>
  );
};

export default UserDetail;