import React from "react";
import { useState, useEffect } from "react";
import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  BarChart,
  AreaChart,
  ResponsiveContainer,
  Area,
} from "recharts";
import {
  Pane,
  Heading,
  Paragraph,
  Combobox,
  InfoSignIcon,
  Tooltip as TooltipUI,
  Button,
} from "evergreen-ui";
import AportacionesYRetiros from "./graficas/AportacionesRetiros";
import Aportaciones from "./flows/Aportaciones";
import Retiros from "./flows/Retiros";
import Historicos from "../admin/etd/rendimientosGlobales/Historicos";
import { motion } from "framer-motion";

const Etd = (props) => {
  const [data, setData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("2023");
  const [showRetiros, setShowRetiros] = useState(false);
  const [showAportaciones, setShowAportaciones] = useState(false);

  const toggleAportaciones = () => {
    console.log("toggleAportaciones" + showAportaciones);
    setShowAportaciones(!showAportaciones);
  };

  const toggleRetiros = () => {
    console.log("toggleRetiros" + showRetiros);
    setShowRetiros(!showRetiros);
  };

  const fecha = new Date(Date.now());
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const handleChange = (e) => {
    console.log(e);
    setSelectedYear(e);
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  const utilidades = props.etd.utilidades;

  /**
   * Este useEffect se encarga de renombrar los meses y de filtrar los datos
   */
  useEffect(() => {
    const filtered = utilidades
      .reduce((acc, item) => {
        if (item.year === selectedYear) {
          const newItem = {
            ...item,
            name: meses[item.mes],
            Monto: item.monto.toFixed(2),
            Porcentaje: item.porcentaje.toFixed(2),
          };
          acc.push(newItem);
        }
        return acc;
      }, [])
      .sort((a, b) => a.mes - b.mes);
    setData(filtered);
    console.log(utilidades, selectedYear);
    console.log(data);
  }, [selectedYear]);

  return (
    <motion.div
      key="etd"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Pane
        display="flex"
        alignItems="left"
        justifyContent="left"
        padding={30}
        className="etd-header"
        width="100%"
        backgroundColor="papayawhip"
      >
        <Heading size={900}>SPC-HLF</Heading>
      </Pane>
      <Pane
        display="flex"
        alignItems="left"
        justifyContent="left"
        padding={30}
        flexDirection="column"
        backgroundColor="whitesmoke"
      >
        <Pane
          paddingTop={100}
          display="flex"
          alignItems="left"
          justifyContent="left"
          flexDirection="column"
          padding={20}
        >
          <Heading size={900}>
            Hola, <span
              style={{
                fontWeight: "bold",
              }}
            >{props.user.nombre} {props.user.apellido}</span>, este es un resumen
            de tu participación en SPC-HLF.
          </Heading>
        </Pane>
        <Pane
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          gap={20}
          padding={20}
          paddingLeft={30}
        >
          <Pane>
            <Heading
              size={800}
              style={{
                color: "green",
                marginBottom: "40px",
              }}
            >
              El valor actual de tu participación es de:{" "}
              {formatter.format(props.etd.balance.toFixed(2))}
            </Heading>
            <Heading size={600}>Utilidades mensuales</Heading>
            <Paragraph>
              En esta sección podrás ver el monto de tus utilidades y el
              porcentaje de ganancia que estas generando.
            </Paragraph>
            <Paragraph> Selecciona el año que deseas ver: </Paragraph>

            <Combobox
              items={["2024", "2025"]}
              onChange={handleChange}
              initialSelectedItem={"2024"}
            />
          </Pane>
          <Pane
            display="flex"
            flexDirection="row"
            height="100%"
            padding={20}
            gap={20}
          >
            <Button
              appearance="primary"
              intent="success"
              onClick={() => toggleAportaciones()}
              style={{
                marginRight: "20px",
                padding: "50px",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              }}
            >
              <Heading size={600}>Solicitar aportación</Heading>
              Inicia el proceso de aportación
            </Button>
            {fecha.getDate() <= 19 && (
              <Button
                appearance="primary"
                intent="danger"
                onClick={() => toggleRetiros()}
                style={{
                  padding: "50px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                }}
              >
                <Heading size={600}>Solicitar retiro extraordinario</Heading>
                Inicia el proceso de retiro
              </Button>
            )}
            {fecha.getDate() > 19 && (
              <Button
                appearance="default"
                intent="none"
                onClick={() => toggleRetiros()}
                style={{
                  padding: "50px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                }}
              >
                <Heading size={600}>Solicitar retiro ordinario</Heading>
                Inicia el proceso de retiro
              </Button>
            )}
            <TooltipUI content="El retiro ordinario esta disponible los ultimos 10 dias del mes">
              <InfoSignIcon />
            </TooltipUI>
          </Pane>
        </Pane>

        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {data.length <= 0 && (
            <Heading size={300}>
              {" "}
              No hay datos para mostrar en este momento
            </Heading>
          )}
          <ResponsiveContainer height={300}>
            <BarChart //Utilidades mensuales
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={"name"} />
              <YAxis
                dataKey="Monto"
                orient="left"
                allowDataOverflow
                type="number"
                tickFormatter={(label) => `$${label}`}
              />
              <Tooltip />
              <Legend />
              <Bar
                type="monotone"
                dataKey="Monto"
                fill="#8884d8"
                barSize={20}
              />
              <Line
                type="monotone"
                dataKey="Porcentaje"
                stroke="#82ca9d"
                fill="#8884d8"
              />
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer height={300}>
            <AreaChart //Utilidades mensuales porcentual
              width={1100}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                dataKey="Porcentaje"
                orient="left"
                allowDataOverflow
                tickFormatter={(label) => `${label}%`}
              />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="Porcentaje"
                stroke="#82ca9d"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <Pane //Aportaciones y retiros
          display="flex"
          alignItems="left"
          justifyContent="left"
          flexDirection="column"
          padding={20}
          paddingLeft={30}
        >
          <Heading size={600}>Aportaciones y retiros</Heading>
          <Paragraph>
            En esta sección podrás ver el monto de tus aportaciones y retiros
            mensuales.
          </Paragraph>

          <AportacionesYRetiros data={props.etd} />
        </Pane>
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        paddingLeft={30}
        justifyContent="center"
        alignItems="center"
      >
        <Heading>Resumen de movimientos globales</Heading>
        <Historicos data={props.etd} />
      </Pane>
      {showAportaciones && (
        <Aportaciones
          showAportaciones={showAportaciones}
          toggleAportaciones={toggleAportaciones}
          user={props.user}
          etd={props.etd}
        />
      )}
      {showRetiros && (
        <Retiros
          showRetiros={showRetiros}
          toggleRetiros={toggleRetiros}
          user={props.user}
          etd={props.etd}
        />
      )}
    </motion.div>
  );
};

export default Etd;
